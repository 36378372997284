<template>
  <ul class="flex h-full">
    <li 
      v-for="(item, index) in items"
      :key="item"
      class="relative flex items-center h-full font-sans text-base base-font-gray-400 pl-4 pr-3"
      :class="[
        { 'base-bg-darker-900': index <= active }, 
        { 'base-font-gray-700': index == active }
      ]"
    >
      {{ item }}
      <div v-if="index == active && active < (items.length - 1)" class="rectangle fixed absolute w-2.5 h-2.5 base-bg-darker-900"></div>
    </li>
  </ul>
</template>

<script>
  export default {
    props: {
      active: {
        type: Number,
        default: 0
      }
    },
    data(){
      return {
        items: ['Start', 'Select Content', 'Analysis', 'Results']
      }
    }
  }
</script>

<style lang="scss" scoped>
.rectangle{
  top: 50%;
  right: 0;
  transform: translateX(50%) translateY(-50%) rotate(45deg);
}
</style>